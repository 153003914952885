import { getProductsByCategoryId } from '@/services/category';
import { getCookie } from 'cookies-next';
import useSWR from 'swr';
import LazyLoading from '../LazyLoading';
// import { event_view_item_list } from '@/utils/gtm';
import UseLocale from '@/hooks/useLocale';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import ProductCard from '../cards/ProductCard';
import useScreenSize from '@/hooks/display/useDisplay';
import Title from './Title';
import { useState } from 'react';

const ProductsWithBg = ({ data, isMobile }) => {
  const handshake = getCookie('handshake');
  const { locale } = UseLocale();

  const tab_products_key = `home-products-list/${data?.['category-id']}/${locale}`;

  const { isLoading: isLoadingProducts, data: productsRes } = useSWR(
    tab_products_key,
    () =>
      getProductsByCategoryId(
        handshake,
        data?.['category-id'],
        '',
        15,
        0,
        false
      ),
    {
      populateCache: true,
      revalidateOnFocus: false,
      // revalidateOnReconnect: false,
      revalidateIfStale: false,
    }
  );
  const products = productsRes?.data?.data?.products;
  const bgImg = data?.[isMobile ? `mobile-img-${locale}` : `img-${locale}`];
  const { screenWidth } = useScreenSize();
  console.log('product-list-bg________________16', bgImg);
  const [swiper, set_swiper] = useState({});

  return products?.length ? (
    <>
      {(data?.[`title-${locale}`] || data?.counter) && (
        <div className='mx-auto'>
          <Title
            swiper={swiper}
            language={locale}
            counter={data?.counter}
            isSwiper={data?.[`title-${locale}`]}
            seeMoreUrl={data?.url}
            title={data?.[`title-${locale}`]}
            selectedUrlKey={data?.url}
          />
        </div>
      )}

      <div
        className='overflow-x-hidden mx-auto w-full'
        style={{
          background: `url(${bgImg}) 0 0  / cover no-repeat `,
          padding: '120px 16px 16px',
          borderRadius: '8px',
        }}
      >
        {isLoadingProducts ? (
          <LazyLoading parentClassName={'min-h-[410px] sm:min-h-[442px]'} />
        ) : (
          <Swiper
            onInit={(e) => set_swiper(e)}
            spaceBetween={isMobile ? 10 : 16}
            key={locale}
            dir={locale === 'en' ? 'ltr' : 'rtl'}
            slidesPerView={isMobile ? 2.2 : 6}
            loop={
              (products?.length > 6 && screenWidth > 1200) || screenWidth < 1200
            }
            modules={[Autoplay]}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
          >
            {products?.map((product, i) => (
              <SwiperSlide key={`product-swiper-2-${i}`}>
                <ProductCard
                  product={product}
                  categoryID={data?.['category-id']}
                  isPriority={isMobile ? i <= 2 : i < 7}
                  loading={
                    isMobile
                      ? i <= 2
                        ? 'eager'
                        : 'lazy'
                      : i > 7
                        ? 'eager'
                        : 'lazy'
                  }
                />
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>
    </>
  ) : null;
};

export default ProductsWithBg;
