import { useState } from 'react';
import ProductSwiper from '../global/swiper/ProductSwiper';
import Title from '../home/Title';

const ProductsSwiperSection = ({
  t,
  language,
  setCart,
  title,
  products,
  fromHomePage,
  backgroundColor,
}) => {
  const [swiper, set_swiper] = useState({});

  return (
    <div
      className={`mx-auto my-7 overflow-x-hidden ${fromHomePage ? '' : 'w-11/12'} sm:my-10 ${backgroundColor && `bg-${backgroundColor}`}`}
    >
      <Title swiper={swiper} t={t} language={language} title={title} isSwiper={true} />
      <div className='mt-2'>
        <ProductSwiper set_swiper={set_swiper} products={products} setCart={setCart} />
      </div>
    </div>
  );
};

export default ProductsSwiperSection;
